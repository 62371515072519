import { Locale, LocaleCode } from '../types/locales'

const locales: Array<Locale> = [
  // The default locale is first in the array.
  // Comment out the locales that you don't use.
  {
    code: LocaleCode.SL,
    file: 'sl.json',
    iso: 'sl-SI',
    name: 'Slovenščina'
  },
  {
    code: LocaleCode.EN,
    file: 'en.json',
    iso: 'en-US',
    name: 'English'
  },
  {
    code: LocaleCode.DE,
    file: 'de.json',
    iso: 'de-DE',
    name: 'Deutsch'
  }
]

export default locales
